import { useDispatch } from 'react-redux'
import { object, string } from 'yup'
import { authenticate } from 'src/store/modules/auth/actions'
import { success } from 'src/utils/toast'
import WithAuth from 'src/components/HOC/WithAuth'
import { AuthBase } from '../components/Auth/AuthBase'

interface AuthValues {
  email: string
  password: string
  fullName?: string
}

export const initialValues: AuthValues = {
  email: '',
  password: '',
}

export const validationSchema = object({
  email: string().required('El correo es requerido').email('Debe ser un correo valido'),
  password: string().required('La contraseña es requerida').min(8, 'No puede tener menos de 8 caracteres').max(16, 'No puede tener mas de 16 caracteres'),
})

const LoginPage = () => {
  const dispatch = useDispatch()

  const handleSubmit = async (values: AuthValues, { setSubmitting }) => {
    try {
      await dispatch(authenticate(values.email, values.password))
      success('Bienvenido a FZ Academia')
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
    }
  }

  return (
    <AuthBase
      onSubmit={handleSubmit}
      initialValues={initialValues}
      isRegister={false}
      validationSchema={validationSchema}
    />
  )
}

export default WithAuth(LoginPage, {
  role: ['no_login'],
  isAuthRequired: false,
  layout: false,
})
